
import { Component, Vue } from 'vue-property-decorator'
import { wareHousingList, TypesTree, AssetList } from '@/types/assetScheduling'
import Edit from '@/views/assetScheduling/list/Edit.vue'

@Component({
  name: 'scheduling',
  components: { Edit }
})
export default class BraceletList extends Vue {
  private tableData: wareHousingList[] = []
  private showDialog = false
  private id = ''
  private info= {
    assetCodeName: '',
    assetType: '',
    assetState: '',
    projectId: '',
    assetRight: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false

  private list: TypesTree[] = []
  private props = {
    children: 'childNode',
    label: 'assetTypeName',
    value: 'assetType'
  }

  private assetStateList = ['空闲', '借用', '调拨', '盘亏', '出售', '报废']
  private assetValueList = ['1', '2', '3', '41', '42', '43']
  private types = ['盘亏', '出售', '报废']
  private equityList = []

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getList()
    this.getData()
    this.getEquityList()
  }

  onEdit (id: string) {
    this.id = id
    this.showDialog = true
  }

  success () {
    this.getData()
    this.id = ''
  }

  // 查询二级类型
  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByList).then(res => {
      this.list = res || []
    })
  }

  // 查询资产信息
  getEquityList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetRightByList).then((res) => {
      this.equityList = res.list || []
    })
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.assetScheduling.selectAssetByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onOperation (type: string, row: AssetList) {
    // console.log('row', row)
    const params = { projectId: row.projectId || '', assetCode: row.assetCode || '' }
    // 领用
    if (type === '1') {
      this.$router.push({ name: 'assetRequisition', params: { ...params, custodianId: row.custodianId } })
    }
    // 退还
    if (type === '2') {
      this.$router.push({ name: 'assetRefund', params: { ...params, custodianId: row.custodianId } })
    }
    // 调拨
    if (type === '3') {
      this.$router.push({ name: 'assetTransferAdd', params: params })
    }
    // 处置
    if (type === '4') {
      this.$router.push({ name: 'assetDisposalAdd', params: params })
    }
  }

  // 导出
  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '导出中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.assetScheduling.exportAsset, {}).finally(() => {
      loading.close()
    })
  }

  onDelete (assetId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.assetScheduling.deleteAsset, { assetId: assetId }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onDetail (assetId: string) {
    this.$router.push({ path: '/asset/' + assetId + '/asset' })
  }
}
