
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { TypesTree, AreaTree, AssetList } from '@/types/assetScheduling'
import { ProjectUser } from '@/types/project'
import { pInteger } from '@/utils/validate'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
@Component({
  components: { UploadFile },
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class AddTypes extends Vue {
  @Prop({ default: false }) private showDialog!: boolean
  @Prop({ default: '' }) private assetId!: string
  private formInfo: AssetList = this.formInfoBak
  private list: TypesTree[] = []
  private areaList: AreaTree[] = []
  private projectUserList: ProjectUser[] = []
  private props = {
    children: 'childNode',
    label: 'assetTypeName',
    value: 'assetType'
  }

  private props1 = {
    children: 'childNode',
    label: 'assetLocationName',
    value: 'assetLocation'
  }

  pickerOptions = {
    disabledDate: (time: Date) => {
      // 大于当前日期禁止选择
      return time.getTime() > Date.now()
    }
  }

  private validtor999 = (rule: any, value: string, callback: Function) => {
    if (+value > 999) {
      return callback(new Error('请输入1-999的数字'))
    }
    return callback()
  }

  private validtor99999 = (rule: any, value: string, callback: Function) => {
    const reg = /^[0-9]+(.[0-9]+)?$/
    if (value) {
      if (!reg.test(value) || Number(value) > 999999999.99) {
        return callback(new Error('请输入0到999999999.99的数'))
      }
    }
    return callback()
  }

  private rules = {
    brand: [
      { required: true, message: '请输入品牌', trigger: ['blur', 'change'] }
    ],
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    assetName: [
      { required: true, message: '请输入资产名称', trigger: ['blur', 'change'] }
    ],
    custodianId: [
      { required: true, message: '请输入保管员', trigger: ['blur', 'change'] }
    ],
    assetType: [
      { required: true, message: '请输入资产分类', trigger: ['blur', 'change'] }
    ],
    locationId: [
      { required: true, message: '请输入存放位置', trigger: ['blur', 'change'] }
    ],
    model: [
      { required: true, message: '请输入型号', trigger: ['blur', 'change'] }
    ],
    purchaseTime: [
      { required: true, message: '请选择购置时间', trigger: ['blur', 'change'] }
    ],
    purchaseAmount: [
      { required: true, message: '请选择购置金额（含税）', trigger: ['blur', 'change'] },
      { validator: this.validtor99999, trigger: ['blur', 'change'] }
    ],
    expectedServiceLife: [
      { validator: pInteger, trigger: ['blur', 'change'] },
      { validator: this.validtor999, trigger: ['blur', 'change'] }
    ],
    fileList: [
      { required: true, message: '请选择资产图片', trigger: ['blur', 'change'] }
    ]
  }

  get formInfoBak () {
    return {
      assetType: '',
      assetName: '',
      brand: '',
      // 型号
      model: '',
      // 设备序列号
      equipmentSerialNumber: '',
      // 存放位置
      locationId: '',
      // 保管员
      custodianId: '',
      // 购置时间
      purchaseTime: '',
      // 购置金额（含税）
      purchaseAmount: '',
      // 预计使用期限（月）
      expectedServiceLife: '',
      // 备注
      remarks: '',
      isEdit: true,
      assetTypeName: '',
      locationName: '',
      custodianName: '',
      projectId: '',
      fileList: [],
      assetRight: '',
      assetRightName: ''
    }
  }

  @Watch('assetId')
  getId (val: string) {
    if (val) {
      this.getDetail(val)
      this.getList()
      this.getAreaList()
    }
  }

  private submitShow = false

  created () {
    this.getList()
  }

  // 查询二级类型
  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByList, {
      level: 2
    }).then(res => {
      this.list = res || []
    })
  }

  // 位置查询二级类型
  getAreaList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetLocationByList, {
      level: 2
    }).then(res => {
      this.areaList = res || []
    })
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.formInfo.projectId
    }).then((res) => {
      this.projectUserList = res.list || []
    })
  }

  getDetail (val: string) {
    this.$axios.get(this.$apis.assetScheduling.selectAssetByAssetId, { assetId: val }).then(res => {
      this.formInfo = res || []
      this.getProjectUserList()
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.formInfo.fileList && this.formInfo.fileList.push(file)
  }

  imgRemove (index: number) {
    this.formInfo.fileList && this.formInfo.fileList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.assetScheduling.updateAsset, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.formInfo = this.formInfoBak
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).clearValidate()
    })
    this.$emit('update:showDialog', false)
    this.$emit('success')
  }
}
